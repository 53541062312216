import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ACTIVE_THEME, ActiveThemeProvider } from './theme/theme-injection';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'psp-root',
  template: `
    <div
      [attr.data-theme]="theme$ | async"
      class="font-sans h-full w-full text-textColor @container/main">
      <!-- NG PRIME TOAST / Don't remove -->
      <p-toast [style]="{ 'font-size': '0.9rem' }"></p-toast>
      <!-- NG PRIME TOAST END -->

      <router-outlet></router-outlet>
    </div>
  `,
  standalone: true,
  imports: [ToastModule, RouterOutlet, CommonModule],
  providers: [MessageService, DialogService],
})
export class AppComponent {
  constructor(@Inject(ACTIVE_THEME) public theme$: ActiveThemeProvider) {}
}

import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@shared/services/auth.service';

export function authGuard() {
  const router = inject(Router);
  const authService = inject(AuthService);

  authService.isAuthenticated() || router.navigate(['/account']);
}

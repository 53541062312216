import { ErrorTailorConfig } from '@ngneat/error-tailor';

export const errorsTailorConfig: ErrorTailorConfig = {
  errors: {
    useValue: {
      required: 'This field is required',
      minlength: ({
        requiredLength,
        actualLength,
      }: {
        requiredLength: string;
        actualLength: string;
      }) => `Expect at least ${requiredLength}, but got ${actualLength}`,
      maxlength: ({
        requiredLength,
        actualLength,
      }: {
        requiredLength: string;
        actualLength: string;
      }) => `Expect maximum ${requiredLength}, but got ${actualLength}`,
      apiError: (error: unknown) => (typeof error === 'string' ? error : 'Unknown error'),
      email: 'Please enter correct email',
    },
  },
};

import { Routes } from '@angular/router';

import { authGuard } from './shared/guards/auth.guard';

export const ROOT_ROUTES: Routes = [
  {
    path: 'account',
    loadChildren: () => import('src/app/auth/auth.routing').then(m => m.AUTH_ROUTES),
  },
  {
    path: '',
    canActivate: [authGuard],
    loadComponent: () =>
      import('src/app/navigation/navigation.component').then(m => m.NavigationComponent),
    loadChildren: () =>
      import('src/app/navigation/navigation.routing').then(m => m.NAVIGATION_ROUTES),
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

import './polyfills';

import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideErrorTailorConfig } from '@ngneat/error-tailor';
import { errorsTailorConfig } from './app/error-tailor/error-tailor.module';
import { provideRouter } from '@angular/router';
import { ROOT_ROUTES } from './app/app.routing';
import { provideAnimations } from '@angular/platform-browser/animations';
import { authTokenErrorInterceptor, authTokenInterceptor } from '@shared/interceptors';

bootstrapApplication(AppComponent, {
  providers: [
    provideErrorTailorConfig(errorsTailorConfig),
    provideRouter(ROOT_ROUTES),
    provideHttpClient(withInterceptors([authTokenInterceptor, authTokenErrorInterceptor])),
    provideAnimations(),
  ],
}).catch(err => console.error(err));
